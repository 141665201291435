import React, { useEffect, useState } from "react";
import styles from "./Settings.module.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import Tabs from "../../components/Tabs";
import { LoaderTypes, TabsNames } from "../../utils/@globalTypes";
import { ActiveTab } from "../../hoc/ActiveTab";
import { RoutesList } from "../../App";
import { tabsList } from "./settingsDatas";
import OwnerSettings from "./OwnerSettings";
import OrganizationSettingWrap from "./OrganizationSettingWrap";
import { useDispatch, useSelector } from "react-redux";
import { PageSelectors, setPageData } from "../../redux/reducers/pageSlice";
import { getCurrentUserInfo, getSettingsData, UserSelectors } from "../../redux/reducers/userSlice";
import { AuthSelectors } from "../../redux/reducers/authSlice";
import Loader from "../../components/Loader";
import TariffInfo from "./TariffInfo";

const Settings = () => {
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(false);

  const user = useSelector(UserSelectors.getOwnerInfo);
  const isLoading = useSelector(PageSelectors.getLoadersData(LoaderTypes.SETTINGS_PAGE));

  const [activeTab, setActiveTab] = useState<TabsNames>();

  useEffect(() => {
    dispatch(
      setPageData({
        pageTitle: "Личный кабинет",
        pageDescription: "Настройки личного кабинета",
      })
    );
    return () => {
      dispatch(setPageData(null));
    };
  }, []);

  useEffect(() => {
    if (user && !isFirstLoad) {
      const { id, entity_id, individual_id } = user;
      dispatch(getSettingsData({ owner_id: id, entity_id, individual_id }));
      setIsFirstLoad(true);
    }
  }, [user, isFirstLoad]);
  
  useEffect(() => {
    dispatch(getCurrentUserInfo());     
  }, []); 

  return !isLoading ? (
    <section className={styles.container}>
      <Tabs
        tabsList={tabsList}
        activeTab={activeTab}
        className={styles.setTabs}
        containerClassName={styles.contTabs}
      >
        <Routes>
          <Route index element={<Navigate to={TabsNames.PERSON_SETTINGS} replace />} />
          <Route
            path={TabsNames.PERSON_SETTINGS}
            element={
              <ActiveTab setActiveTab={setActiveTab} path={TabsNames.PERSON_SETTINGS}>
                <OwnerSettings />
              </ActiveTab>
            }
          />
          <Route
            path={TabsNames.ORGANIZATION_SETTINGS}
            element={
              <ActiveTab setActiveTab={setActiveTab} path={TabsNames.ORGANIZATION_SETTINGS}>
                <OrganizationSettingWrap />
              </ActiveTab>
            }
          />
          <Route
            path={TabsNames.OWNER_TARIFF}
            element={
              <ActiveTab setActiveTab={setActiveTab} path={TabsNames.OWNER_TARIFF}>
                <TariffInfo />
              </ActiveTab>
            }
          />
          <Route
            path={RoutesList.any.path}
            element={<Navigate to={RoutesList.notFound.fullPath} replace />}
          />
        </Routes>
      </Tabs>
    </section>
  ) : (
    <Loader />
  );
};

export default Settings;
