import { useEffect, useState } from 'react';
import { BookingFormType, FormBookingType } from '../../../redux/types/bookingTypes';
import { InputModeType, InputType } from '../../../utils/@globalTypes';
import Input from '../../Input';
import BookingSectionTitle from '../BookingSectionTitle';
import { REQUIRED_FIELD_ERROR, TELEGRAM_REQ, TELEPHONE_ERROR } from '../../../utils/constants';
import styles from './BookingContactBlock.module.scss';
import IntlTelInput from '../../IntlTelInput';
import { FormikProps, useFormikContext } from 'formik';

type ContactblockProps ={
  windowType?:BookingFormType,
  formik:FormikProps<FormBookingType>,  
} 

const BookingContactBlock = ({
    windowType,
    formik         
  }:ContactblockProps) => {
  
  const [isTelephoneValid, setIsValid] = useState(false)
  const {handleChange, values, errors, initialValues, validateField, setFieldTouched, touched, setFieldError,dirty} = formik;
  const {setFieldValue} = useFormikContext();
  
useEffect(() => {  
  if(dirty){ 
  isTelephoneValid
  ?values.phone.phone_number
  ?setFieldError("phone.phone_number", TELEPHONE_ERROR)
  :setFieldError("phone.phone_number", REQUIRED_FIELD_ERROR)
  :setFieldError("phone.phone_number", undefined)}  
}, [values.phone, isTelephoneValid, touched.phone]);

useEffect(() => {
  if(touched.email){
    validateField("email")
  };
}, [touched, values]);

useEffect(() => {
  if(touched.telegram){
    validateField("telegram")
  };
}, [touched, values]);
  
  return (
    <div className={styles.wrap} id='wrap'>
      <BookingSectionTitle title="Контакты"/>
      <div className={styles.container}>
        <div>
          <Input
            id="email"
            value={values.email}
            title={windowType===BookingFormType.view ? "Электронная почта" : "Электронная почта*"}
            name="email"
            placeholder="Введите электронную почту"
            containerClassName={styles.inputСontainer}
            className= {styles.booking}
            type={InputType.EMAIL}
            inputMode={InputModeType.EMAIL}
            errText={errors.email}
            onChange={handleChange("email")}
            onBlur={() => setFieldTouched("email")}
            banSymbols={/\s/g}
            disabled={windowType===BookingFormType.view ? true : false}
            isBooking={true}
            autoComplete="email"                          
          />
        </div>
        <IntlTelInput
          id="phone"
          title={windowType===BookingFormType.view ? "Мобильный телефон" : "Мобильный телефон*"}
          value={values.phone}
          placeholder="Введите номер"
          onChange={(val) => {
            setFieldValue("phone", val)}}
          errText={errors.phone?.phone_number}
          setError={()=>{}}
          onBlur={() => setFieldTouched("phone")}
          setIsValidate={setIsValid}
          isBooking={true}
          disabled={windowType===BookingFormType.view ? true : false}
          preloadedData={initialValues.phone}   // первоначальная загрузка номера телефона в компонент
        />
        <div>
          <Input
            id="telegram"
            value={values.telegram}
            title="Telegram"
            name="telegram"
            placeholder={windowType===BookingFormType.view ? "-" : "@nickname"}
            containerClassName={styles.inputСontainer}
            className= {styles.booking}
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}
            errText={errors.telegram}
            onChange={handleChange("telegram")}
            onBlur={() => setFieldTouched("telegram")}
            banSymbols={/\s/g}
            requirementsText={windowType===BookingFormType.view ? undefined: TELEGRAM_REQ}
            disabled={windowType===BookingFormType.view ? true : false}
            isBooking={true}              
          />
        </div>         
      </div>
    </div>    
  )
}

export default BookingContactBlock ;