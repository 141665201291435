import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import API from "../api";
import { setLoadersData, setMessage, setModalWindowData } from "../reducers/pageSlice";
import { LoaderTypes, MessageTypes, ModalWindowTypes } from "../../utils/@globalTypes";
import { ErrorResponseData, ResponseData, TariffOrderType, TariffsInfoType } from "../types/@types";
import { createFreeTarifOrder, createPaidTarifOrder, getTariffsInfo, setPriceList } from "../reducers/tariffsSlice";
import callCheckingAuth from "./callCheckingAuth";
import { TARIF_ORDER } from "../../utils/constants";
import { getCurrentUserInfo } from "../reducers/userSlice";

function* getTariffInfoWorker() {
  yield put(setLoadersData({ type: LoaderTypes.TARIFFS_PAGE, value: true }));
  const { ok, data, status }: ApiResponse<ResponseData<TariffsInfoType[]>> = yield callCheckingAuth(
    API.getTariffsInfo,    
  );
    
  if (data && ok) {    
    yield put(setPriceList(data.results));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения тарифов",
          code: status,
        })
      );
    }
  }
  yield put(setLoadersData({ type: LoaderTypes.TARIFFS_PAGE, value: false }));
}
function* setFreeTarifWorker() {
  yield put(setLoadersData({ type: LoaderTypes.TARIFFS_PAGE, value: true }));
  const { ok, status }: ApiResponse<any> = yield callCheckingAuth(
    API.setFreeTarifOrder, 
    
  );
    
  if (ok) {    
    yield put(getCurrentUserInfo());
    yield put(setMessage({
      status: MessageTypes.POSITIVE,
      message: "Заявка успешно отправлена",
      code: 204,
    }));

  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка отправки заявки. Попробуйте еще раз",
          code: status,
        })
      );
    }
  }
  yield put(setLoadersData({ type: LoaderTypes.TARIFFS_PAGE, value: false }));
}
function* setPaidTarifWorker(action: PayloadAction<TariffOrderType>) {
  yield put(setLoadersData({ type: LoaderTypes.TARIFFS_PAGE, value: true }));
  
  const { ok, status }: ApiResponse<ResponseData<TariffOrderType>> = yield callCheckingAuth(
    API.setTarifOrder,  
    action.payload  
  );
    
  if (ok) {   
    localStorage.setItem(TARIF_ORDER, "true") 
    yield put(setMessage({
      status: MessageTypes.POSITIVE,
      message: "Заявка успешно отправлена",
      code: 200,
    }));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка отправки заявки. Попробуйте еще раз",
          code: status,
        })
      );
    }
  }
  yield put(setLoadersData({ type: LoaderTypes.TARIFFS_PAGE, value: false }));
}

export default function* tariffSaga() {
  yield all([
    takeLatest(getTariffsInfo, getTariffInfoWorker),    
    takeLatest(createFreeTarifOrder, setFreeTarifWorker),
    takeLatest(createPaidTarifOrder, setPaidTarifWorker),
  ]);
}