import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./Tooltip.module.scss";
import { TooltipIcon } from "../../assets/icons";
import { useClickOutside } from "../../utils/functions";
import classNames from "classnames";
import useWindowDimensions from "../../utils/useWindowDimensions";

type TooltipProps = {
  requirementsText: string | string[];
  error?: boolean;
};

const Tooltip: FC<TooltipProps> = ({ requirementsText, error }) => {
  const [isWindowOpened, setIsWindowOpened] = useState(false);
  const [leftPosition, setLeftposition] = useState<string>("");
  const [topPosition, setTopposition] = useState<string>("");
  const [orientation, setOrientation] = useState<string>("");

  const menuRef = useRef<HTMLDivElement | null>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  useClickOutside(menuRef, btnRef, () => setIsWindowOpened(false));

  const onOpenWindowBtnClick = (e:any) => {
    e.preventDefault();
    setIsWindowOpened((prevstate) => !prevstate);
  };
  const { width, height} = useWindowDimensions();

  useEffect(() => {
    if (btnRef.current) {      
      const iconRect = btnRef.current.getBoundingClientRect();  
      const tooltipWidth = width>760?300:220
      
      // Проверим, помещается ли подсказка в правую часть экрана
      if (iconRect.left + tooltipWidth +40> width) {
          setLeftposition(`${width-20-tooltipWidth-iconRect.left}px`); // Сдвинем налево
          setTopposition(`calc(100% + 4px)`)          
      } 
      if (menuRef.current){
        const tooltipRect = menuRef.current.getBoundingClientRect(); 
        // Проверим, помещается ли подсказка снизу экрана
        if (tooltipRect.height + iconRect.bottom +20 > height) {
            setTopposition(`${height-10-tooltipRect.height-iconRect.bottom}px`)
            if (iconRect.left + tooltipWidth +30< width){
              setLeftposition(`calc(100% + 4px)`)
            } else if (iconRect.left + 20> tooltipWidth){
              setLeftposition(`${-tooltipWidth}px`)
            }         
        } 
      }
    }
  },[width, height, isWindowOpened])

  //проверяем ориентацию девайса. 
  useEffect(() => {   
    if (window.matchMedia("(orientation: portrait)").matches&&orientation!=="portrait") {
      setOrientation("portrait")
    }    
    if (window.matchMedia("(orientation: landscape)").matches&&orientation!=="landscape") {
      setOrientation("landscape")
    }
  },[window.matchMedia("(orientation: landscape)"), window.matchMedia("(orientation: portrait)"), orientation])
  
//при изменении ориентации с landscape на portrait подсказка автоматом закроется
  useEffect(() => { 
    if (window.matchMedia("(orientation: portrait)").matches&&orientation!=="portrait"&&isWindowOpened) {
      setIsWindowOpened(false)
    }
  },[window.matchMedia("(orientation: landscape)"), window.matchMedia("(orientation: portrait)"), isWindowOpened])
  
  return (
    <div className={styles.wrapper}>
      {isWindowOpened && (
        <div
          className={classNames(styles.menu, {
            [styles.errorMenu]: error,
          })}
          ref={menuRef}
          style={leftPosition||topPosition?{left:leftPosition, top:topPosition}:undefined}
        >
          {Array.isArray(requirementsText) ? (
            <ul className={styles.requirementsList}>
              {requirementsText.map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          ) : (
            <p>{requirementsText}</p>
          )}
        </div>
      )}
      <button
        className={classNames(styles.btn, {
          [styles.activeBtn]: isWindowOpened,
          [styles.errorBtn]: error,
        })}
        onClick={onOpenWindowBtnClick}
        ref={btnRef}
      >
        <TooltipIcon />
      </button>
    </div>
  );
};

export default Tooltip;
