import * as  Yup  from  'yup' ;
import { REQUIRED_FIELD_ERROR2, TOTAL_INFO_ERROR } from '../../../../utils/constants';

const useFeedValidationSchema = () => {

  const validationSchema=Yup.object().shape ({  
    has_breakfast:Yup
    .boolean(),            
    has_lunch:Yup
    .boolean(),         
    has_dinner:Yup
    .boolean(),      
    breakfast:Yup
    .string()
    .when("has_breakfast", {
      is:(has_breakfast: boolean) => has_breakfast === true,
      then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
      otherwise: (validationSchema) => validationSchema.nullable().default("")
    }),
    
    lunch:Yup
    .string()
    .default("")
    .when("has_lunch", {
      is:(has_lunch: boolean) => has_lunch === true,
      then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
      otherwise: (validationSchema) => validationSchema.nullable().default("")
    }),    
    dinner:Yup
    .string()
    .when("has_dinner", {
      is:(has_dinner: boolean) => has_dinner === true,
      then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
      otherwise: (validationSchema) => validationSchema.nullable().default("")
    }),
    has_kidsPrice:Yup
    .boolean(),
    has_kidsFree:Yup
    .boolean(),
    ageKidsFree:Yup
    .string()
    .when("has_kidsFree", {
      is:(has_kidsFree: boolean) => has_kidsFree === true,
      then: (validationSchema) => validationSchema.max(12,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
      otherwise: (validationSchema) => validationSchema.nullable().default(null)
    }),
    kids:Yup.array().of(
      Yup.object().shape({
        startAge: Yup.string().required(REQUIRED_FIELD_ERROR2),
        endAge: Yup.string().required(REQUIRED_FIELD_ERROR2),
        kidsBreakfast: Yup.string()
        .when("has_breakfast", {
          is:(has_breakfast: boolean) => has_breakfast === true,
          then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
          otherwise: (validationSchema) => validationSchema.nullable().default(null)
        }),
        kidsLunch: Yup.string()
        .when("has_lunch", {
          is:(has_lunch: boolean) => has_lunch === true,
          then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
          otherwise: (validationSchema) => validationSchema.nullable().default("")
        }), 
        kidsDinner: Yup.string() 
        .when("has_dinner", {
          is:(has_dinner: boolean) => has_dinner === true,
          then: (validationSchema) => validationSchema.max(8,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR2),
          otherwise: (validationSchema) => validationSchema.nullable().default("")
        }),         
      })
    ),
  })
return validationSchema;
}
export default useFeedValidationSchema