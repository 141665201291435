import React, { useEffect, useState } from "react";
import styles from "./ObjectEditing.module.scss";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BasicSettings from "./BasicSettings";
import { LoaderTypes, TabsNames } from "../../../utils/@globalTypes";
import {
  MainObjectSelectors,
  getMainObjectTabsData,
  setCurrentObject,
} from "../../../redux/reducers/mainObjectSlice";
import { setRentalObjectList } from "../../../redux/reducers/rentalObjectSlice";
import Tabs from "../../../components/Tabs";
import { PageSelectors } from "../../../redux/reducers/pageSlice";
import Loader from "../../../components/Loader";
import { ActiveTab } from "../../../hoc/ActiveTab";
import { TabListType } from "../../../components/Tabs/@types";
import { RoutesList } from "../../../App";
import RentalObjects from "./RentalObjects";
import EmployeeManagement from "./EmployeeManagement";
import ControlSettings from "./ControlSettings";
import FeedingSettings from "./FeedingSettings";
import { getCurrentUserInfo } from "../../../redux/reducers/userSlice";

const TABS_LIST: TabListType = [
  {
    title: "Базовые настройки",
    path: TabsNames.BASIC_SETTINGS,
  },
  {
    title: "Сдаваемые объекты",
    path: TabsNames.OBJECT_EDITING,
  },
  {
    title: "Настройки управления",
    path: TabsNames.CONTROL_SETTINGS,
  },
  {
    title: "Настройки питания",
    path: TabsNames.FEEDING_SETTINGS,
  },
  {
    title: "Управление сотрудниками",
    path: TabsNames.EMPLOYEE_MANAGEMENT,
  },
];

const ObjectEditing = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const currentObject = useSelector(MainObjectSelectors.getCurrentObject);
  // // const currentContact = useSelector(MainObjectSelectors.getCurrentContactPerson); -CONTACTS
  const isLoading = useSelector(PageSelectors.getLoadersData(LoaderTypes.OBJECT_EDITING_PAGE));

  const [activeTab, setActiveTab] = useState<TabsNames>();

  useEffect(() => {
    if (id) {
      dispatch(
        getMainObjectTabsData({
          data: id,
          callback: () => {
            navigate(RoutesList.notFound.fullPath, { replace: true });
          },
        })
      );
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentObject(null));
      // dispatch(setAllContactPersons([])); -CONTACTS
      dispatch(setRentalObjectList([]));
    };
  }, []);

  return !isLoading && id ? (
    <>
      <h2 className={styles.objectName}>{currentObject ? currentObject.name : "Объект"}</h2>
      <div className={styles.wrapper}>
        <Tabs tabsList={TABS_LIST} activeTab={activeTab}>
          <Routes>
            <Route index element={<Navigate to={TabsNames.BASIC_SETTINGS} replace />} />
            <Route
              path={TabsNames.BASIC_SETTINGS}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.BASIC_SETTINGS}>
                  <BasicSettings
                    pageId={id}
                    // contactsArr={contactList} -CONTACTS
                    currentObject={currentObject}
                  />
                </ActiveTab>
              }
            />
            <Route
              path={TabsNames.OBJECT_EDITING}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.OBJECT_EDITING}>
                  <RentalObjects pageId={id} />
                </ActiveTab>
              }
            />
            <Route
              path={TabsNames.CONTROL_SETTINGS}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.CONTROL_SETTINGS}>
                  <ControlSettings />
                </ActiveTab>
              }
            />
            <Route
              path={TabsNames.FEEDING_SETTINGS}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.FEEDING_SETTINGS}>
                  <FeedingSettings />
                </ActiveTab>
              }
            />
            <Route
              path={`${TabsNames.EMPLOYEE_MANAGEMENT}/*`}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.EMPLOYEE_MANAGEMENT}>
                  <EmployeeManagement />
                </ActiveTab>
              }
            />
            <Route
              path={RoutesList.any.path}
              element={<Navigate to={RoutesList.notFound.fullPath} replace />}
            />
          </Routes>
        </Tabs>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default ObjectEditing;
