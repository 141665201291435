import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./Input.module.scss";
import { EyeIcon } from "../../assets/icons";
import { InputModeType, InputType } from "../../utils/@globalTypes";
import Tooltip from "../Tooltip";
import { useDispatch } from "react-redux";
import { IMaskInput } from "react-imask";

type InputProps = {
  id?: string;
  value: string;
  title?: string;
  name?: string;
  placeholder: string;
  // onKeyDown?: {
  //   key: string;
  //   func: () => void;
  // };
  type: InputType;
  inputMode?: InputModeType;
  disabled?: boolean;
  errText?: string;
  containerClassName?: string;
  className?: string;
  onChange: (value: string) => void;
  onBlur?: (value: boolean) => void;
  requirementsText?: string | string[];
  banSymbols?: RegExp | string;
  customBanSymbols?: (value: string) => string;
  autoComplete?: string;
  addHiddenInput?: boolean;
  isBooking?: boolean;
  maxLength?: number;
  mask?: {
    min?: number;
    max?: number;
    scale?: number;
    padFractionalZeros?: boolean;
    thousandsSeparator?: string;
    radix?: string;
    mask?: any;
    definitions?: {};
    unmask?: boolean;
  };

  //   {
  //     (e: React.FocusEvent<any>): void;
  //     <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  // }
};

// const inputStyles = {
//   [InputType.Primary]: styles.primaryInput,
//   [InputType.Secondary]: styles.secondaryInput,
// };

const Input: FC<InputProps> = React.memo(
  ({
    id,
    value,
    title,
    name,
    placeholder,
    type,
    inputMode,
    disabled,
    errText,
    className,
    containerClassName,
    onChange,
    // onKeyDown,
    onBlur,
    requirementsText,
    banSymbols,
    customBanSymbols,
    maxLength,
    autoComplete,
    addHiddenInput,
    isBooking, //признак использования компонента для просмотра/редактирования/создания брони. Отдельная стилизация
    mask, // для type = "mask"
  }) => {
    const [passVisibility, setPassVisibility] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    // const inputClassName = inputStyles[inputType];

    // const onEnterDown = (event: KeyboardEvent<HTMLInputElement>) => {
    //   if (event.key === onKeyDown?.key) {
    //     onKeyDown.func();
    //   }
    // };

    const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
      const currentValue = banSymbols
        ? e.target.value.replace(banSymbols, "")
        : customBanSymbols
        ? customBanSymbols(e.target.value)
        : e.target.value;
      onChange(currentValue);
    };

    const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    };

    const onBlurInput = () => {
      onBlur && onBlur(true);
    };

    const passBtn = () => {
      setPassVisibility(!passVisibility);
    };

    return (
      <div className={classNames(containerClassName && styles[containerClassName], 
      isBooking && styles.small)}>
        {title && (
          <div className={styles.titleWrapper}>
            <p className={styles.title}>{title}</p>
            {requirementsText && <Tooltip requirementsText={requirementsText} error={!!errText} />}
          </div>
        )}
        <div className={styles.inputWrapper}>
          {addHiddenInput && <input type="text" className={styles.hiddenInput} />}
          {type === InputType.TEXTAREA ? (
            <textarea
              id={id}
              value={value}
              className={classNames(
                styles.input,
                className,
                className && styles[className],
                styles.textarea,
                {
                  [styles.disabledInp]: disabled,
                  [styles.errorInput]: errText,
                }
              )}
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={onBlurInput}
              onChange={onChangeTextarea}
              maxLength={maxLength}
            ></textarea>
          ) : type === InputType.MASK && mask ? (
            <IMaskInput
              mask={mask.mask}
              className={classNames(styles.input, className, {
                [styles.activeInp]: value.length > 0,
                [styles.disabledInp]: disabled,
                [styles.errorInput]: errText,
              })}
              value={value}
              min={mask.min}
              max={mask.max}
              readOnly={disabled}
              onAccept={(value, unmaskedValue, mask) => {
                onChange && onChange(unmaskedValue._unmaskedValue);
              }}
              scale={mask.scale}
              padFractionalZeros={mask.padFractionalZeros}
              thousandsSeparator={mask.thousandsSeparator}
              onBlur={onBlurInput}
              unmask={mask.unmask ?? true}
              placeholder={placeholder}
              radix={mask.radix ?? "."}
              definitions={mask.definitions}
            />
          ) : (
            <input
              id={id}
              value={value}
              className={classNames(styles.input, className, {
                [styles.activeInp]: value.length > 0,
                [styles.disabledInp]: disabled,
                [styles.errorInput]: errText,
              })}
              name={name}
              type={passVisibility ? "text" : type}
              inputMode={inputMode ? inputMode : undefined}
              // onKeyDown={onEnterDown}
              placeholder={placeholder}
              disabled={disabled}
              onChange={onChangeText}
              onBlur={onBlurInput}
              autoComplete={autoComplete}
              ref={inputRef}
            />
          )}
          {type === "password" && (
            <div
              className={classNames(styles.passBtn, {
                [styles.activePassBtn]: passVisibility,
              })}
              onClick={passBtn}
            >
              <EyeIcon />
            </div>
          )}
        </div>
        {errText && <p className={styles.errorText}>{errText}</p>}
      </div>
    );
  }
);

export default Input;
