
import { Link } from "react-router-dom";
import { RoutesList } from "../../App";
import { ButtonType } from "../../utils/@globalTypes";
import Button from "../Button";
import styles from "./UnavailableContent.module.scss";


const UnavailableContent = () => {
  const content = "Эта функция недоступна для вашего текущего тарифа."
  const content1 = "Чтобы получить доступ к данному функционалу, Вам необходимо изменить тарифный план."
  return(
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        <h3 className={styles.title}>
          Внимание
        </h3>
        <div className={styles.content}>
          <p>{content}</p>
          <p>{content1}</p>
        </div>
        <div className={styles.btnWrap}>
          <Link to={RoutesList.prices.fullPath} >
            <Button 
              title="Перейти к тарифам"
              type={ButtonType.PRIMARY}            
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default UnavailableContent;