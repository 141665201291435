import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkerboard from './../Checkerboard';
import { getChessObjects, getBookingList } from "./../../../redux/reducers/bookingSlice";
import { setModalWindowData, setPageData } from "../../../redux/reducers/pageSlice";

import styles from "./CheckerboardWrap.module.scss";
import { getCurrentUserInfo } from "../../../redux/reducers/userSlice";

const CheckerboardWrap = () => {
  const dispatch = useDispatch();
    
  useEffect(() => {
    dispatch(
      setPageData({
        pageTitle: "Интерактивный календарь",
        pageDescription: "Учет броней, оплат и доступности номеров",
      })
    );

    return () => {
      dispatch(setPageData(null));
    };
  }, []);

  useEffect(() => {
    dispatch(getChessObjects({}));
    dispatch(getBookingList({}));
    dispatch(getCurrentUserInfo()); 
  }, []);

  return (
    <Checkerboard/>
  )
}
export default CheckerboardWrap;