import React, { useEffect } from "react";
import "./App.scss";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import SignIn from "./pages/FormPages/SignIn";
import SignUp from "./pages/FormPages/SignUp";
// import EmailInput from "./pages/FormPages/AccessRecovery/EmailInput";
// import ConfirmCode from "./pages/FormPages/AccessRecovery/ConfirmCode";
// import NewPassword from "./pages/FormPages/AccessRecovery/NewPassword";
import CheckerboardWrap from "./pages/Checkerboard/CheckerboardWrap";
import ObjectManagement from "./pages/ObjectManagement";
import BookingList from "./pages/BookingList";
import AuthProvider from "./pages/AuthProvider";
import { useDispatch } from "react-redux";
import { getMainObjectTypes } from "./redux/reducers/mainObjectSlice";
import Settings from "./pages/Settings";
import { getRentalObjectTypes } from "./redux/reducers/rentalObjectSlice";
import { getRentalObjectRoomTypes } from "./redux/reducers/roomSlice";
import Prices from "./pages/Prices";
import NotFound from "./pages/NotFound";

export const RoutesList = {
  path: "/",
  signIn: {
    path: "sign-in",
    fullPath: "/sign-in",
  },
  signUp: {
    path: "sign-up",
    fullPath: "/sign-up",
  },
  checkerboard: {
    path: "checkerboard",
    fullPath: "/checkerboard",
  },
  objectManagement: {
    path: "object-management/*",
    part: "object-management",
    fullPath: "/object-management",
    objectEditingChild: {
      path: "object-editing/:id/*",
      part: "object-editing",
      fullPath: "/object-management/object-editing",
    },
    rentalObjectEditingChild: {
      path: ":mainId/rental-object-editing/:rentalId/*",
      part: "rental-object-editing",
    },
  },
  settings: {
    path: "settings/*",    
    fullPath: "/settings",    
  },
  bookingList: {
    path: "booking",
    fullPath: "/booking",
  },
  prices: {
    path: "prices",
    fullPath: "/prices",
  },
  notFound: {
    path: "not-found",
    fullPath: "/not-found",
  },
  any: {
    path: "*",
  },
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={RoutesList.path}>
      <Route element={<AuthProvider isFormPages={true} />}>
        <Route path={RoutesList.signIn.path} element={<SignIn />} />
        <Route path={RoutesList.signUp.path} element={<SignUp />} />
        {/* <Route path={"access-recovery-email"} element={<EmailInput />} />
        <Route path={"access-recovery-confirm-code"} element={<ConfirmCode />} />
        <Route path={"access-recovery-new-password"} element={<NewPassword />} /> */}
      </Route>
      <Route element={<AuthProvider isFormPages={false} />}>
        <Route index element={<Navigate to={RoutesList.checkerboard.path} replace />} />
        <Route path={RoutesList.checkerboard.path} element={<CheckerboardWrap />} />
        <Route path={RoutesList.bookingList.path} element={<BookingList />} />
        <Route path={RoutesList.objectManagement.path} element={<ObjectManagement />} />
        <Route path={RoutesList.settings.path} element={<Settings />} />
        <Route path={RoutesList.prices.path} element={<Prices />} />
      </Route>
      <Route
        path={RoutesList.any.path}
        element={<Navigate to={RoutesList.notFound.path} replace />}
      />
      <Route path={RoutesList.notFound.path} element={<NotFound/>} />
    </Route>
  )
);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMainObjectTypes({}));
    dispatch(getRentalObjectTypes({}));
    dispatch(getRentalObjectRoomTypes({}));    
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
