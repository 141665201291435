import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageSelectors, setPageData } from "../../redux/reducers/pageSlice";
import PriceCard from "../../components/PriceCard";
import { tariffs } from "../../utils/tarifDatas";
import styles from "./Prices.module.scss";
import PricePossibilities from "../../components/PricePossibilities";
import { Arrow2Icon } from "../../assets/icons";
import classNames from "classnames";
import RadioButton from "../../components/RadioButton";
import { getTariffsInfo, TariffsSelectors } from "../../redux/reducers/tariffsSlice";
import { LoaderTypes } from "../../utils/@globalTypes";
import Loader from "../../components/Loader";
import { getCurrentUserInfo } from "../../redux/reducers/userSlice";

const Prices = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTariffsInfo({}));
    dispatch(getCurrentUserInfo());     
  }, []);
  
  const isLoading = useSelector(PageSelectors.getLoadersData(LoaderTypes.TARIFFS_PAGE));
  useEffect(() => {
    dispatch(
      setPageData({
        pageTitle: "Информация о тарифах",
        pageDescription: "Настройки тарифного плана",
      })
    );

    return () => {
      dispatch(setPageData(null));
    };
  }, []);
  const current = useSelector(TariffsSelectors.getCurrentTarif);  
  const priceList = useSelector(TariffsSelectors.getPriceList);
  const discont = useSelector(TariffsSelectors.getTariffsDiscont);
  const payingTarif = useSelector(TariffsSelectors.getPayingTarif);
  const fractionDigits = useSelector(TariffsSelectors.getTarifFractionDigits)

  const [periodChoice, setPeriodChoice] = useState<number>(0)

  const RadioButtonList = [
    <div className={styles.title} key="0">На 1 месяц</div>,     
    <div key="1">
      <div className={styles.title}>На 6 месяцев</div>
      <div className={classNames(styles.subtitle,{
        [styles.active]:periodChoice===1
      })}>
        Экономия <span>{discont.halfYear}%</span>
      </div>
      </div>, 
    <div key="2">
    <div className={styles.title}>На 12 месяцев</div>
    <div className={classNames(styles.subtitle,{
      [styles.active]:periodChoice===2
    })}>
      Экономия <span>{discont.year}%</span>
    </div>
  </div>
  ];
  
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isTriggerToScroll, setIsTriggerToScroll] = useState(false)
  const abilityBlock = document.getElementById("prs") as HTMLElement;

  const handlerLink = () => {
    setIsOpen(true)
    setIsTriggerToScroll(true)    
  }

  useEffect(() => {    
    if (isOpen && abilityBlock && isTriggerToScroll) {
      abilityBlock.scrollIntoView({behavior: 'smooth', block:"nearest"});
    }
    setIsTriggerToScroll(false)
  }, [isOpen, isTriggerToScroll]);

  const cards = tariffs.map((item,index)=>{
    const isPaying = payingTarif===item.code ? true : false
    const period = periodChoice===0? 1:periodChoice===1?6:12
    const displayYearPrice = period===12
  ? priceList[item.code].yearPrice
  : period===6
  ? (Number(priceList[item.code].halfYearPrice)*2).toFixed(fractionDigits).toString()
  : (Number(priceList[item.code].monthlyPrice)*12).toFixed(fractionDigits).toString()
    
  const displayMonthPrice = period===12
  ? (Number(priceList[item.code].yearPrice)/12).toFixed(fractionDigits).toString()
  : period===6
  ? (Number(priceList[item.code].halfYearPrice)/6).toFixed(fractionDigits).toString()
  : priceList[item.code].monthlyPrice
  
    return (
      <div key={index}>
        <PriceCard 
          tarif = {item}
          isCurrent={item.code===current? true: false}  
          period = {period } 
          prices = {priceList[item.code]}
          isPaying = {isPaying}
          discont = {discont}
          displayYearPrice = {displayYearPrice}
          displayMonthPrice = {displayMonthPrice}
        />
      </div>
    )
  })

  return isLoading 
  ? (
    <Loader />
  ) :(
    <>
      <div className={styles.container}>
        {/* Скоро здесь будут тарифы */}
        {(!priceList.PRO_PLUS.monthlyPrice||!priceList.PRO.monthlyPrice||!priceList.START.monthlyPrice) ?
        (<p>В данный момент информация о тарифных планах недоступна</p>)
        :(<section className={styles.blockWrap}> 
          <div className={styles.radioBtnWrap}>
            <RadioButton
              id="tarif"
              name="tarif"
              btnsList={RadioButtonList}
              onClick={setPeriodChoice}
              activeTab={periodChoice}
              size="price"
            />
          </div>
          <h4 className={styles.link} onClick={handlerLink}>Сравнить тарифы</h4>
          <div className={styles.cardsWrapper}>
            {cards}
          </div>
        </section>) 
          }
        <div className={styles.abilityWrap} id = "prs">
          <h3 className={styles.toggleTitle} onClick={()=>setIsOpen(!isOpen)} >
            {isOpen&&"Скрыть весь список возможностей"}
            {!isOpen&&"Показать весь список возможностей"}
            <span className={classNames(styles.arrow, isOpen && styles.arrowOpen )}>
              <Arrow2Icon fill="#0F2664"/>
            </span>
          </h3>
          {isOpen&&
          <div className={styles.possContainer}>
            <PricePossibilities />
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default Prices;