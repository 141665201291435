import * as  Yup  from  'yup' ;
import { 
  EMAIL_ERROR,
  EMAIL_REG, 
  MIDDLENAME_REG, 
  NAME_REG, 
  REQUIRED_FIELD_ERROR, 
  TELEGRAM_REG, 
  TELEPHONE_ERROR, 
  TOTAL_INFO_ERROR 
} from '../../utils/constants';
import { useSelector } from 'react-redux';
import { BookingSelectors } from '../../redux/reducers/bookingSlice';

const useValidationSchema = () => {
  const isValid = useSelector(BookingSelectors.getIsTelephoneValid);
  
  const validationSchema=Yup.object().shape ({  
  rental_object:Yup
    .number()
    .transform((value, originalValue) => {
      return originalValue ? Number(originalValue) : undefined;
    }) 
    .required(REQUIRED_FIELD_ERROR),   
  status:Yup
    .string()    
    .required(REQUIRED_FIELD_ERROR), 
  check_in_date:Yup
    .date()
    .required(REQUIRED_FIELD_ERROR),
  check_out_date:Yup  
    .date()
    .required(REQUIRED_FIELD_ERROR)
    .test('is-greater', 'Дата выезда должна быть позже даты заезда', function (check_out_date) {
      const { check_in_date } = this.parent;
      return check_in_date && check_out_date && check_out_date > check_in_date;
    })
    .test('is-less', 'Бронь не может превышать 180 дней', function (check_out_date) {
      const { check_in_date } = this.parent;
      return check_in_date && check_out_date && check_out_date <= new Date(check_in_date.getTime() + 180 * 24 * 60 * 60 * 1000);
    }),    
  check_in_time:Yup
    .string()
    .required(REQUIRED_FIELD_ERROR), 
  check_out_time:Yup
    .string()
    .required(REQUIRED_FIELD_ERROR), 
  adult:Yup
    .number()
    .integer()
    .positive()
    .min(1)
    .max(50)
    .required(REQUIRED_FIELD_ERROR),
  children:Yup
    .array(Yup.number())
    .notRequired(),      
  has_animals:Yup
    .boolean()
    .notRequired(),
  animals_info:Yup
    .string()
    .when("has_animals",{
    is:(has_animals: boolean) => has_animals === true,
    then: (validationSchema) => validationSchema.max(100,TOTAL_INFO_ERROR).required(REQUIRED_FIELD_ERROR),
    otherwise: (validationSchema) => validationSchema.strip()     
  }),
  total:Yup
    .number()
    .required(REQUIRED_FIELD_ERROR)
    .transform((value, originalValue) => {
      return originalValue ? Number(originalValue) : undefined;
    })    
    .min(0,'Сумма не может быть отрицательной'),    
  paid:Yup
    .number()
    .transform((value, originalValue) => {
      return originalValue ? Number(originalValue) : undefined;
    })    
    .test('is-less', "Сумма оплаты не может превышать общую стоимость", function (paid) {      
      const { total } = this.parent;
      return (((total?Number(total):0)-(paid?Number(paid):0)>=0));
    }), 
  rest:Yup
    .number()
    .transform((value, originalValue) => {
      return originalValue ? Number(originalValue) : 0;
    })
    .min(0)
    .nullable(),
  last_name:Yup
    .string()
    .max(60, TOTAL_INFO_ERROR)
    .required(REQUIRED_FIELD_ERROR)
    .matches(NAME_REG, TOTAL_INFO_ERROR),
  first_name:Yup
    .string()
    .max(60, TOTAL_INFO_ERROR)
    .required(REQUIRED_FIELD_ERROR)
    .matches(NAME_REG, TOTAL_INFO_ERROR),
  second_name:Yup
    .string()
    .max(60, TOTAL_INFO_ERROR)
    .trim()
    .notRequired()
    // .nullable()    
    .matches(MIDDLENAME_REG,TOTAL_INFO_ERROR), 
  nationality:Yup
    .string()
    .required(REQUIRED_FIELD_ERROR),
  birth_day:Yup
    .date()
    .notRequired()
    .nullable()
    .max(new Date()),
  sex:Yup
    .string()
    .nullable()
    .notRequired(),
  email:Yup
    .string()    
    .required(REQUIRED_FIELD_ERROR)
    .email(EMAIL_ERROR)
    .matches(EMAIL_REG, EMAIL_ERROR),    
  telegram:Yup
    .string()
    .notRequired()
    .matches(TELEGRAM_REG, TOTAL_INFO_ERROR),
  phone:Yup.object().shape ({ 
    country_code: Yup.string(),
    phone_code: Yup.string(),
    phone_number: Yup.string().required(REQUIRED_FIELD_ERROR)
    .test('is-valid-phone', TELEPHONE_ERROR, function(value) {
      return !isValid
    }),                    
  }),
  comment:Yup
    .string()
    .trim()
    .notRequired()
    .max(550, TOTAL_INFO_ERROR)
})
return validationSchema;
};

export default useValidationSchema;
